import * as Slot from 'BaxterScript/version/web/core/Slot';
import * as State from 'BaxterScript/version/web/core/State';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.breakpointRefresh;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const getSettings = (pageId, containerId, slotId) => {
  const settings = globalThis.Baxter.config.slots.settings?.breakpointRefresh;
  return globalThis.Baxter.context.configurationService.getById(settings, pageId, containerId, slotId);
};

const isEnabledForSlot = (pageId, containerId, slotId) => {
  const settings = getSettings(pageId, containerId, slotId);
  return settings && settings.enabled;
};

const refreshSlots = async () => {
  const breakpoints = State.getBreakpoints();
  if (breakpoints && breakpoints.length) {
    const breakpoint = State.getBreakpoint();
    const currentBreakpoint = breakpoints.find((val) => val <= window.innerWidth);
    if (currentBreakpoint !== breakpoint) {
      State.setBreakpoint();

      const slotsState = State.getSlots() || {};
      await Promise.all(
        Object.keys(slotsState).map(async (containerId) => {
          const slot = slotsState[containerId];
          if (isEnabledForSlot(State.getPageId(), slot.containerId, slot.id)) {
            console.debug('[SLOTS][BREAKPOINTREFRESH][REFRESHSLOTS] Slot.set', containerId, slot.params);
            await Slot.set(containerId, slot.params, false);
          }
        })
      );
    }
  }
};

export default {
  getSettings,
  isEnabledForSlot,
  refreshSlots,
};
